<template>
    <div class="" v-if="addFlag" :class="[isWap ? '' : 'mask']">
        <div class="" :class="[isWap ? '' : 'slider']">
            <div v-if="(!isWap)" class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
            <div v-if="(!isWap)" class="title">新建 <img class="title_close" src="@/assets/imgs/close.png" alt=""
                    @click="hideMask"></div>
            <div class="content">
                <div class="content_row content_detail" v-for="(item, index) in  dataAddClient" :key="index">
                    <template v-if="item.iswrite == 1">
                        <label class="info" for="" style="line-height:32px"><span v-if="item.type == 'SingleText' && item.name == 'tmjcpb_corp__name'" class="redS">*</span>{{ item.text }}:</label>
                        <!-- 数字 -->
                        <div class="content_detail_right" v-if="item.type == 'number'">
                            <a-input type="number" v-model="item.value"></a-input>
                        </div>
                        <!-- 单行文本 -->
                        <div class="content_detail_right" v-if="item.type == 'SingleText' && item.name != 'tmjcpb_corp__name'">
                            <a-input v-model="item.value"></a-input>
                        </div>
                        <!-- 单行文本 -->
                        <div class="content_detail_right"
                            v-if="item.type == 'SingleText' && item.name == 'tmjcpb_corp__name'">
                            <a-input v-model="item.value" @change="onChangeName(item.value)"></a-input>
                            <p v-show="isShow" :style="{ color: 'red' }">该名称已存在，请重新输入</p>
                        </div>
                        <!-- <div class="content_detail_right"
                            v-if="item.name == 'tmjcpb_corp__name' && item.type == 'SingleText'">
                            -- @input="handleChange($event,index)" @blur="blur" --
                            <a-input placeholder="" v-model="item.value" />
                            <div v-if="item.name == 'tmjcpb_corp__name' && nameList.length != 0"
                                :class="[companyFlag ? 'company' : 'hide']">
                                <a-list size="small" bordered>
                                    <a-list-item v-for="(item2, index2) in nameList" :key="index2"
                                        @click="checkName(item2, index)">
                                        {{ item2["tmjcpb_corp__name"] }}
                                    </a-list-item>
                                </a-list>
                            </div>
                        </div> -->

                        <!-- 多行文本 -->
                        <div class="content_detail_right" v-else-if="item.type == 'multiText'">
                            <a-textarea v-model="item.value">{{ item.value }}</a-textarea>
                        </div>
                        <!-- 单选标签 -->
                        <div class="content_detail_right" v-else-if="item.type == 'radioTag'">
                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                            <!-- <a-radio-group v-model="item.value">
                                <a-radio class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                    {{option.value}}
                                </a-radio>
                            </a-radio-group> -->
                            <a-radio-group v-model="item.value" button-style="solid">
                                <a-radio-button class="radioTag" v-for="(option, index2) in item.options" :key="index2"
                                    :value="option.value">
                                    {{ option.value }}
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <!-- 多选标签 -->
                        <div class="content_detail_right" v-else-if="item.type == 'checkTag'">
                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                            <a-checkbox-group v-model="item.value" :options="item.options">
                                <span slot="label" slot-scope="{ value }">{{ value }}</span>
                            </a-checkbox-group>
                        </div>
                        <!-- 时间 
                        <div class="content_detail_right" v-else-if="item.type == 'time'">
                            <a-time-picker v-model="item.value" /> 
                        </div>-->
                        <!-- 日期 -->
                        <div class="content_detail_right" v-else-if="item.type == 'date'">
                            <a-date-picker v-model="item.value" />
                        </div>
                        <!-- 日期时间 -->
                        <div class="content_detail_right" v-else-if="item.type == 'dateTime'">
                            <a-date-picker show-time v-model="item.value" />
                        </div>
                        <!-- 下拉框 -->
                        <div class="content_detail_right" v-else-if="item.type == 'select'">
                            <a-select size="large" v-model="item.value">
                                <a-select-option v-for="(option, index2) in item.options" :key="index2"
                                    :value="option.value">{{ option.value }}</a-select-option>
                            </a-select>
                        </div>
                        <!-- 下拉框 多选-->
                        <div class="content_detail_right" v-else-if="item.type == 'selects'">
                            <a-select size="large" mode="multiple" v-model="item.value">
                                <a-select-option v-for="(option, index2) in item.options" :key="index2"
                                    :value="option.value">{{ option.value }}</a-select-option>
                            </a-select>
                        </div>
                        <!-- 附件-->
                        <div class="content_detail_right" v-else-if="item.type == 'annex'">
                            <a :href="url + '/scrm/index/Download?id=' + item.value"
                                v-if="item.value">{{ item.value2 }}</a>
                            <label class="fileLabel upFile" for="annex"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="annex" value="" @change="annexUpload($event, index)">
                        </div>
                        <!-- 单张图 -->
                        <div class="content_detail_right" v-else-if="item.type == 'image'">
                            <label v-if="item.value" class="fileLabel" for="image"><img
                                    :src="url + '/scrm/index/getFile?id=' + item.value"
                                    style="margin-bottom:10px"></label>
                            <label v-else class="fileLabel" for="image"><img src="../assets/imgs/add2.png"
                                    alt=""></label>
                            <input type="file" id="image" value="" @change="imgUpload($event, index)">
                        </div>
                        <!-- 多张图-->
                        <div class="content_detail_right" v-else-if="item.type == 'images'">
                            <template v-for="(o, i) in item.value">
                                <img :key="i" v-if="o" :src="url + '/scrm/index/getFile?id=' + o"
                                    style="margin-bottom:10px">
                            </template>
                            <label class="fileLabel" for="images"><img src="../assets/imgs/add2.png" alt=""></label>
                            <input type="file" id="images" value="" @change="imgUploads($event, index)">
                        </div>
                        <!-- 地区-->
                        <div class="content_detail_right" v-else-if="item.type == 'city'">
                            <a-cascader placeholder="请选择地区" :options="citys" v-model="item.value" />
                        </div>
                    </template>
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>
    </div>
</template>
<script>
import { requestXml, fileUpload, ajaxUrl } from '../assets/js/request';
import citys from '@/assets/js/city.js';
import "../assets/css/client.css"
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        isWap: {
            type: Boolean,
            default: false
        },
        userid: {
            type: String,
        }
    },
    watch: {
        isWap: function (val) {
            console.log(val, 666)
            this.addFlag = val;
            this.wap = false;
        },
        userid: function (val) {
            if (val) {
                this.linkmanUserid = val;
            }
        }
    },
    data() {
        return {
            url: ajaxUrl,
            citys,
            isShow:false,
            data: [],
            form: {},
            nameList: [],
            company: "",
            ids: 0,
            companyFlag: true,
            // 手机端侧边栏添加
            linkmanUserid: "",
            // 手机端添加
            wap: false
        };
    },
    mounted: function () {
        for (let i = 0; i < citys.length; i++) {
            citys[i].value = citys[i].label
            for (let j = 0; j < citys[i].children.length; j++) {
                citys[i].children[j].value = citys[i].children[j].label
                for (let k = 0; k < citys[i].children[j].children.length; k++) {
                    citys[i].children[j].children[k].value = citys[i].children[j].children[k].label
                }
            }
        }
        // console.log(citys)
        this.addClient();
    },

    methods: {
        onChangeName(value){
            if(value==''){
                this.isShow=false
                return
            }
            requestXml("/scrm/corp/getCorpByName", "POST", (res) => {
                if(res==1){
                    this.isShow=true
                }else{
                    this.isShow=false
                }
            }, { "name": value })
        },
        checkName(value, index) {
            this.nameList = [];
            this.dataAddClient[index].value = value.tmjcpb_corp__name;
            this.ids = value.tmjcpb_corp__ids;
        },
        getNameList() {
            requestXml("/scrm/Corp/getUnBindCorp", "POST", (res) => {
                this.nameList = res;
            }, { "key": this.company })
        },
        handleChange(e) {
            this.company = e.target.value;
            // this.form["tmjcpb_corp__name"] = e.target.value;
            this.getNameList();
        },
        blur() {
            // this.companyFlag = false;
        },
        hideMask() {
            this.$emit('updateAddFlag', false);
        },
        addClient() {
            // 获取客户添加功能需要填写字段
            requestXml("/scrm/corp/getfield?type=add", "GET", (res) => {
                // var obj = {};
                // 将获取到的name 和对应的值添加到一个对象中 做form中的v-model
                // for(var i=0;i<res.length;i++){
                //     if(res[i].type == "text"){
                //         obj[res[i].name]= "";
                //     }else if(res[i].type == "radio"){
                //         obj[res[i].name]= res[i].options[0];
                //     }
                // }
                // this.form = obj;
                
                this.isShow=false
                this.data = res;
            })
        },
        // 附件
        annexUpload(e, index) {
            fileUpload(e, (res) => {
                this.dataAddClient[index].value = res.id;
                this.dataAddClient[index].value2 = res.name;
            })
        },
        // 单张图
        imgUpload(e, index) {
            console.log(e)
            fileUpload(e, (res) => {
                this.dataAddClient[index].value = res.id;
            })
        },
        // 多张图
        imgUploads(e, index) {
            fileUpload(e, (res) => {
                this.dataAddClient[index].value?this.dataAddClient[index].value.push(res.id):this.dataAddClient[index].value=[res.id]
            })
        },
        // 添加客户
        submit() {
            let corp = this.dataAddClient;
            let corpObj = {};
            if (this.linkmanUserid) {
                corpObj.tmjcpb_linkman__userid = this.linkmanUserid;
            }

            if (corp.tmjcpb_corp__name == "") {
                this.$message.info("请填写客户名称");
                return false;
            } else {
                console.log(corp)
                for (let i = 0; i < corp.length; i++) {
                    if (corp[i].value && corp[i].iswrite == 1) {
                        if (corp[i].type == 'date' || corp[i].type == 'dateTime' || corp[i].type == 'time') {
                            console.log(corp[i].value._d, 8888)
                            // corp[i].value2 = corp[i].value._d;
                            corp[i].value = parseInt(corp[i].value._d.getTime() / 1000);

                        }
                        if (corp[i].type == 'selects' && corp[i].value.length != 0) {
                            corp[i].value = corp[i].value.join(",");
                        }
                        if (corp[i].type == 'checkTag' && corp[i].value.length != 0) {
                            corp[i].value = corp[i].value.join(",");
                        }
                        if (corp[i].type == 'city' && corp[i].value.length != 0) {
                            corp[i].value = corp[i].value.join(",");
                        }
                        if (corp[i].type == 'images' && corp[i].value.length != 0) {
                            corp[i].value = corp[i].value.join(",");
                        }
                        corpObj[corp[i].name] = corp[i].value;
                    }
                }
                corpObj['tmjcpb_corp__ids'] = this.ids;
            }
            requestXml("/scrm/corp/add", "POST", (res) => {
                if (res == "success") {
                    console.log(this.wap)
                    if (this.wap) {
                        if (this.linkmanUserid) {
                            this.$router.go(-1);
                        } else {
                            this.$router.push({
                                path: "/scrm_wap/client/clientList",
                            })
                        }
                    } else {
                        this.$router.go(-1);
                    }
                } else {

                    for (let i = 0; i < corp.length; i++) {
                        if (corp[i].value && corp[i].iswrite == 1) {
                            if (corp[i].type == 'selects' && corp[i].value.length != 0) {
                                corp[i].value = corp[i].value.split(",");
                            }
                            if (corp[i].type == 'checkTag' && corp[i].value.length != 0) {
                                corp[i].value = corp[i].value.split(",");
                            }
                            if (corp[i].type == 'city' && corp[i].value.length != 0) {
                                corp[i].value = corp[i].value.split(",");
                            }
                            if (corp[i].type == 'images' && corp[i].value.length != 0) {
                                corp[i].value = corp[i].value.split(",");
                            }
                        }
                    }
                }
            }, corpObj)

            // this.$emit('updateAddFlag', false);
            // requestXml("/scrm/corp/add","POST",() => {
            //     this.$emit('updateClientList');
            // },corpObj)
        }
    },
    computed: {
        dataAddClient() {
            let result = this.data.filter(item => {
                console.log(item, 'item')
                if (item.iswrite == 1) return item;
            });

            console.log(result, 'result')
            return result
        }
    }
};
</script>
<style>
/* .content .content_row{
        margin: 1.5rem 0;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .content_detail_right{
        width: calc(100% - 17rem);
        border-radius: 4px;
        background-color: #F9F9F9;
    } */
.hide {
    display: none;
}
</style>
